<!-- =========================================================================================
  File Name: ECommerceShop.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>

    <div class="algolia-header ">
      <div class="flex md:items-end items-center justify-between flex-wrap">
        <!-- TOGGLE SIDEBAR BUTTON -->
        <feather-icon
          class="inline-flex lg:hidden cursor-pointer mr-4"
          icon="MenuIcon"
          @click.stop="toggleFilterSidebar"/>
      </div>
    </div>


    <div class="knowledge-base-jumbotron rounded-lg" :style="{'background-image': 'url(https://cdn.discordapp.com/attachments/867619072731381780/867627219451248670/grad.png)'}" style="background-position: center">
      <div style="backdrop-filter: blur(25px)"
           class="knowledge-base-jumbotron-content lg:p-24 md:p-16 sm:p-8 p-4 mb-base rounded-lg">
        <div class="flex">
          <feather-icon icon="ShoppingBagIcon" svgClasses="h-16 w-16 mr-6"/>
          <h1 class="mb-1 text-white" style="font-size: 60px">Rapid Shop</h1>
        </div>
      </div>
    </div>


    <div id="algolia-content-container" class="relative clearfix">
      <div>
        <div class="items-grid-view vx-row match-height">
          <div v-for="item in items_local" :key="item.id"
               class="vx-col sm:w-1/1 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5">
            <item-grid-view :item="item">
              <template slot="action-buttons">
                <div class="flex flex-wrap action-buttons-shop">
                  <div class="item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer">
                    <feather-icon icon="ShoppingBagIcon" svgClasses="h-4 w-4"/>
                    <span v-if="isInCart(item.id)" class="text-sm font-semibold ml-2" @click="cartButtonClicked(item)">VIEW IN CART</span>
                    <span v-else class="text-sm font-semibold ml-2" @click="viewProductPage(item)">VIEW PRICING OPTIONS</span>
                  </div>
                </div>
              </template>
            </item-grid-view>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {
    ItemGridView: () => import('./components/ItemGridView.vue'),
  },
  data() {
    return {
      isFilterSidebarActive: true,
      clickNotClose: true,
      items: [],
      items_local: [],
      productsTotal: 0,
      paginationPageSize: 64,
      currentPage: 1,

      prices: [
        {
          name: 'Any',
          min: 0,
          max: 10000,
        },
        {
          name: '< $100',
          min: 0,
          max: 100,
        },
        {
          name: '$500 < $100',
          min: 100,
          max: 500,
        },
        {
          name: '$1000 < $500',
          min: 500,
          max: 1000,
        },
        {
          name: '> $0100',
          min: 1000,
          max: 10000,
        },
      ],
      prices_active: 0,

      services: [
        {id: '150629791219843072', name: 'Rapid Flash Cards'},
        {id: '150636328084570112', name: 'Rapid Simulations'},
      ],
      service_active: 0,
    };
  },
  computed: {
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },

    // GRID VIEW
    isInCart() {
      return (itemId) => this.$store.getters['eCommerce/isInCart'](itemId);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth();
    },
  },
  methods: {

    refine(type, value) {

      if (type === 'service') {
        this.items_local = this.items.filter(a => a.service_id === value);
      }

      if (this.items_local.length === 0 || type === 'clear') {
        this.items_local = this.items;
        this.service_active = undefined;
      }

    },

    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },

    // GRID VIEW - ACTIONS
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    additemInCart(item) {
      this.$store.dispatch('eCommerce/additemInCart', item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.id) ?
      this.$router.push('/checkout').catch(() => {
      }) :
      this.$router.push(`/shop/${item.id}`).catch(() => {
      });
    },
    loadProductBuffer(page = 0) {

      if (page === 0) {
        page = this.currentPage;
      }

      this.$http.get(`billing/products?amount=${this.paginationPageSize}&page=${page}`)
        .then(response => {

          if (response.data.data) {
            this.items = response.data.data.filter(a => a.status === 'active');
            this.items_local = this.items;
          }

        })
        .catch(exception => {

          let error = 'An unknown error occurred while loading the service list';
          if (exception.response) {
            error = exception.response.data.error.description;
          }

          return this.$vs.notify({
            title: 'Failed to load service list',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    loadServices() {
      this.$http.get(`services`)
        .then(response => {
          if (response.status === 200) {
            this.services = response.data.data;

            if (this.$route.query.service) {

              let serviceName = this.$route.query.service.toLowerCase().replaceAll(/\s/g, '');

              let services = this.services.filter(service => {
                let _name = service.name.toLowerCase().replaceAll(/\s/g, '');
                return _name === serviceName;
              });

              if (services.length > 0) {
                this.refine('service', services[0].id);
                this.service_active = services[0].id;
              }

            }

          }
        })
        .catch(e => {

        });
    },
    viewProductPage(item) {
      this.$router.push(`/shop/${item.id}`).catch(() => {
      });
    }
  },
  created() {
    this.setSidebarWidth();

  },
  mounted() {

    this.$http.get(`billing/products/counts?amount=${this.paginationPageSize}`)
      .then(response => {

        if (response.data.data) {

          const data = response.data.data;

          if (data.pages) {
            this.totalPages = data.pages;
            this.productsTotal = data.total;
            this.loadProductBuffer();
            this.loadServices();
          }

        }

      })
      .catch(exception => {

      });

  },
};

</script>


<style lang="scss">
.item-details {
  min-height: 225px;
}

//.item-description {
//  min-height: 175px;
//}


#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {

    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: .5rem;
    }

    .vs-sidebar--items {
      border-radius: .5rem;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}

</style>

